import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "https://myflightzone.com.au/special-flights",
    name: "Special Flights",
    type: "none",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "https://myflightzone.com.au/who-we-are",
    name: "Who We Are",
    type: "none",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "https://myflightzone.com.au/blog",
    name: "Blog",
    type: "none",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "https://myflightzone.com.au/contact",
    name: "Contact",
    type: "none",
    isNew: true,
  },
];
