import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const PAYMENT_INVOICE_CHECKOUT_INIT = `${API_URL}/payment/invoice/init`;

interface InitiateCheckoutRequest {
  email_address: string;
  full_name: string;
  mobile: string;
}

interface InitiateCheckoutResponse {
  orderId: string;
  sessionId: string;
  merchant: string;
}

export const initiateInvoiceCheckout = async (
  payload: InitiateCheckoutRequest
): Promise<InitiateCheckoutResponse> => {
  try {
    const response = await axios.post(
      PAYMENT_INVOICE_CHECKOUT_INIT,
      payload
    );
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while initiating checkout");
  }
};
