import { Tab } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import { FC, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useFormik } from "formik";
import * as Yup from "yup";
import { initiateInvoiceCheckout } from "../../data/payment/_requests";

import PayInvoicePreProcessing from "./PayInvoicePreProcessing";

export interface PayInvoiceProps {
  className?: string;
}

interface Payload {
  email_address: string;
  full_name: string;
  mobile: string;
  currency: string;
  invoice_number: string;
  invoice_amount: number;
  total_amount: number;
}

export type PaymentParams = {
  sessionId: string;
  orderId: string;
  merchant: string;
  currency: string;
  invoice_number: string;
  invoice_amount: number;
  total_amount: number;
};

const validationSchema = Yup.object().shape({
  currency: Yup.string()
    .oneOf(["AUD", "LKR"])
    .required("Please select your preferred currency for the payment."),
  customer_name: Yup.string().required("Customer Name is required"),
  email_address: Yup.string()
    .email("Invalid email address")
    .required("Email Address is required"),
  mobile: Yup.string().required("Mobile is required"),
  invoice_number: Yup.string().required("Invoice Number is required"),
  invoice_amount: Yup.number()
    .typeError("Invoice Amount must be a number")
    .required("Invoice Amount is required"),
  recaptcha: Yup.string().required("Please complete the reCAPTCHA"),
});

const PayInvoice: FC<PayInvoiceProps> = ({ className = "" }) => {
  const [isCreatingCustomer, setCreatingCustomer] = useState(false);

  const [paymentParams, setPaymentParams] = useState<PaymentParams | null>(
    null
  );

  const [surcharge, setSurcharge] = useState<number>(0);

  const [errorMessage, setErrorMessage] = useState("");

  const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
    ? process.env.REACT_APP_RECAPTCHA_SITE_KEY
    : "";

  let search = useLocation().search;
  let params = new URLSearchParams(search);
  let checkoutVersion = params.get("checkoutVersion");

  useEffect(() => {
    // Get the result from local storage
    const result = localStorage.getItem("paymentResult");
    console.log("result ::: ", result);
    if (result || checkoutVersion) {
      console.log("result ::: ", result);
      window.location.href = "/success_payment";
    }
  }, [checkoutVersion]);

  const surcharge_percentage_aud: number = parseFloat(
    process.env.REACT_APP_COMMWEB_SURCHARGE || "1"
  );
  const surcharge_percentage_lkr: number = parseFloat(
    process.env.REACT_APP_COMMERCIAL_SURCHARGE || "2"
  );

  const handleFieldChange = (e: any) => {
    console.log("e ::: ", e);
    console.log("formik.values ::: ", formik.values);
    formik.handleChange(e);
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    let currentInvoiceAmount =
      fieldName === "invoice_amount"
        ? fieldValue
        : formik.values?.invoice_amount;
    let currentCurrency =
      fieldName === "currency" ? fieldValue : formik.values?.currency;

    let currentSurcharge = 0;
    if (currentCurrency === "AUD") {
      currentSurcharge =
        Number(currentInvoiceAmount) * (surcharge_percentage_aud / 100);
    } else if (currentCurrency === "LKR") {
      currentSurcharge =
        Number(currentInvoiceAmount) * (surcharge_percentage_lkr / 100);
    }

    console.log("currentCurrency :: ", currentCurrency);
    console.log("currentSurcharge :: ", currentSurcharge);
    console.log("currentInvoiceAmount :: ", currentInvoiceAmount);

    const total_amount = Number(currentInvoiceAmount) + currentSurcharge;
    console.log("total_amount :: ", total_amount);

    setSurcharge(currentSurcharge);
    formik.setFieldValue("total_amount", total_amount.toFixed(2));
  };

  const initialValues = {
    currency: "",
    customer_name: "",
    email_address: "",
    mobile: "",
    invoice_number: "",
    invoice_amount: 0,
    total_amount: 0,
    recaptcha: "",
  };

  const handleConfirmAndPay = async (values: any) => {
    setErrorMessage("");
    setCreatingCustomer(true);

    const recaptchaResponse = formik.values.recaptcha;

    if (!recaptchaResponse) {
      setErrorMessage("Please complete the reCAPTCHA verification.");
      return;
    }

    try {
      const {
        customer_name,
        email_address,
        mobile,
        currency,
        invoice_number,
        invoice_amount,
        total_amount,
      } = values;

      const payload: Payload = {
        email_address: email_address,
        full_name: customer_name,
        mobile: mobile,
        currency: currency,
        invoice_number: invoice_number,
        invoice_amount: invoice_amount,
        total_amount: total_amount,
      };

      const response = await initiateInvoiceCheckout(payload);

      console.log("response ::: ", response);

      const { orderId, sessionId, merchant } = response;
      setPaymentParams({
        orderId,
        sessionId,
        merchant,
        currency,
        invoice_number,
        invoice_amount,
        total_amount,
      });

      setCreatingCustomer(false);
    } catch (error) {
      console.log("ERROR :: ", error);
      // Handle error
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleConfirmAndPay,
  });

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        {errorMessage && <div className="text-red-500">{errorMessage}</div>}
        <form onSubmit={formik.handleSubmit}>
          <div>
            <h3 className="text-2xl font-semibold">Invoice Information</h3>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
            <div className="mt-6">
              <Tab.Group>
                <Tab.Panels>
                  <Tab.Panel className="space-y-5">
                    <label className="block">
                      <Label>Select Currency</Label>
                      <select
                        className="mt-1"
                        {...formik.getFieldProps("currency")}
                        onChange={handleFieldChange}
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        <option value="AUD">AUD</option>
                        <option value="LKR">LKR</option>
                      </select>
                      {formik.touched.currency && formik.errors.currency && (
                        <div className="text-red-500">
                          {formik.errors.currency}
                        </div>
                      )}
                    </label>
                    <label className="block">
                      <Label>Full name</Label>
                      <Input
                        name="customer_name"
                        type="text"
                        className="mt-1"
                        value={formik.values.customer_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.customer_name &&
                        formik.errors.customer_name && (
                          <div className="text-red-500">
                            {formik.errors.customer_name}
                          </div>
                        )}
                    </label>
                    <label className="block">
                      <Label>Email</Label>
                      <Input
                        name="email_address"
                        type="text"
                        className="mt-1"
                        value={formik.values.email_address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email_address &&
                        formik.errors.email_address && (
                          <div className="text-red-500">
                            {formik.errors.email_address}
                          </div>
                        )}
                    </label>
                    <label className="block">
                      <Label>Mobile</Label>
                      <Input
                        name="mobile"
                        type="text"
                        className="mt-1"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.mobile && formik.errors.mobile && (
                        <div className="text-red-500">
                          {formik.errors.mobile}
                        </div>
                      )}
                    </label>
                    <label className="block">
                      <Label>Invoice Number</Label>
                      <Input
                        name="invoice_number"
                        type="text"
                        className="mt-1"
                        value={formik.values.invoice_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.invoice_number &&
                        formik.errors.invoice_number && (
                          <div className="text-red-500">
                            {formik.errors.invoice_number}
                          </div>
                        )}
                    </label>
                    <label className="block">
                      <Label>Invoice Amount</Label>
                      <Input
                        name="invoice_amount"
                        type="text"
                        className="mt-1"
                        value={formik.values.invoice_amount}
                        onChange={handleFieldChange}
                        onBlur={handleFieldChange}
                      />
                      {formik.touched.invoice_amount &&
                        formik.errors.invoice_amount && (
                          <div className="text-red-500">
                            {formik.errors.invoice_amount}
                          </div>
                        )}
                    </label>
                    <div
                      className="bg-blue-200 border-blue-400 text-blue-700 shadow-xl listingSectionSidebar__wrap"
                      role="alert"
                    >
                      <strong className="font-bold"
                        style={{
                          fontSize: "12px",
                        }}>Surcharge</strong>
                      <span
                        className="block sm:inline"
                        style={{
                          fontSize: "10px",
                          margin: "0",
                        }}
                      >
                        A surcharge of {surcharge.toFixed(2)} (
                        {formik.values?.currency === "AUD"
                          ? surcharge_percentage_aud
                          : surcharge_percentage_lkr}
                        %) will be applied to all card payments.
                      </span>
                    </div>
                    <label className="block">
                      <Label>Total</Label>
                      <Input
                        name="total_amount"
                        type="text"
                        className="mt-1"
                        value={formik.values.total_amount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.total_amount &&
                        formik.errors.total_amount && (
                          <div className="text-red-500">
                            {formik.errors.total_amount}
                          </div>
                        )}
                    </label>

                    <label className="block">
                      <Label>reCAPTCHA</Label>
                      <ReCAPTCHA
                        type="image"
                        {...formik.getFieldProps("recaptcha")}
                        sitekey={reCaptchaSiteKey}
                        onChange={(response) =>
                          formik.setFieldValue("recaptcha", response)
                        }
                        onBlur={() => formik.setFieldTouched("recaptcha", true)}
                        onExpired={() => formik.setFieldValue("recaptcha", "")}
                      />

                      {formik.touched.recaptcha && !formik.values.recaptcha && (
                        <div className="text-red-500">
                          Please complete the reCAPTCHA.
                        </div>
                      )}
                    </label>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
          <div>
            <div className="mt-6">
              <Tab.Group>
                <Tab.Panels></Tab.Panels>
              </Tab.Group>
              <div className="pt-8">
                {isCreatingCustomer && (
                  <ButtonPrimary loading>In progress...</ButtonPrimary>
                )}

                {/* SUBMIT */}
                {!isCreatingCustomer && (
                  <ButtonPrimary type="submit">
                    Proceed to Payment
                  </ButtonPrimary>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  if (paymentParams) {
    return <PayInvoicePreProcessing paymentParams={paymentParams} />;
  }

  return (
    <div className={`nc-PayInvoice ${className}`}>
      <div
        className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row lg:hidden flex-[3]  !mx-auto  mt-10 mb-20"
        style={{ textAlign: "center" }}
      ></div>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        {/* <div className="hidden lg:block flex-grow">{renderSidebar()}</div> */}

        <div className="hidden lg:block flex-grow"></div>
      </main>
    </div>
  );
};

export default PayInvoice;
